/**
 *  @module Picker
 *  @description 一些选择器的封装.
 * */

/**
 * 打开单位选择器
 * @param {any} data {'nature':'单位类型1开始','disableSelectedNature':'禁用选择单位类型','isMultiple':'是多选还是单选'}
 * @param {Function} callBack  关闭后的回调 会返回选择器选择的数据
 * @param {any} viewOption {'winNode':'挂在的window对象，例如传入window.top (可选,默认为window)','title':'标题(可选,默认为单位选择)', 'sizew':'', sizeh, time, maxmin, offset}
 */
export function openUnitsView(data, callBack, viewOption) {
  let { nature, disableSelectedNature, isMultiple } = data || {};
  var params = [];
  if (nature != null && nature != undefined) params.push(`nature=${nature}`);
  if (disableSelectedNature != null && disableSelectedNature != undefined)
    params.push(`disableSelectedNature=${disableSelectedNature}`);
  if (isMultiple != null && isMultiple != undefined)
    params.push(`isMultiple=${isMultiple}`);

  let { winNode, title, sizew, sizeh, time, maxmin, offset } = viewOption || {};
  if (!title) title = "单位选择";
  var fn = function (data) {
    if (data && callBack) callBack.apply(null, [data]);
  };
  window.top.EventBus.once("EVENT_BACKUNITS", fn);
  var win = !winNode ? window : winNode;
  win.aopenNew(
    "/BasePicker/UnitsPickerView/" + MyCore.encryptAes(params.join("&"), true),
    title,
    sizew,
    sizeh,
    () => {
      window.top.EventBus.removeEventListener("EVENT_BACKUNITS", fn);
    },
    maxmin,
    time,
    offset
  );
}

/**
 * 打开人员选择器
 * @param {any} data {'isMultiple':'是多选还是单选','selectedIds':'当前已经选择的账号数组(可选，数组类型)','disabledIds':'要禁用的帐号数组(可选，数组类型)'}
 * @param {Function} callBack 关闭后的回调 会返回选择器选择的数据
 * @param {any} viewOption {'winNode':'挂在的window对象，例如传入window.top (可选,默认为window)','title':'标题(可选,默认为单位选择)', 'sizew':'', sizeh, time, maxmin, offset}
 */
export function openUsersView(data, callBack, viewOption) {
  let { isMultiple, selectedIds, disabledIds } = data || {};
  var params = [];
  if (isMultiple != null && isMultiple != undefined)
    params.push(`isMultiple=${isMultiple}`);
  //用缓存处理 路由参数可能会爆炸
  removeLocalData("TEMP_SELECTED_USERS");
  if (selectedIds && Array.isArray(selectedIds) && selectedIds.length > 0) {
    //如果是单选传入多个肯定不行
    if (isMultiple == 0 && selectedIds.length > 1) {
      aalert("单选时候不能传入多个已选账号");
      return;
    }
    setLocalData("TEMP_SELECTED_USERS", selectedIds.join(","));
  }

  if (disabledIds && Array.isArray(disabledIds) && disabledIds.length > 0) {
    setLocalData("TEMP_DISABLE_USERS", disabledIds.join(","));
  }

  let { winNode, title, sizew, sizeh, time, maxmin, offset } = viewOption || {};
  if (!title) title = "人员选择";
  var fn = function (data) {
    if (data && callBack) callBack.apply(null, [data]);
  };
  window.top.EventBus.once("EVENT_BACKPERSONS", fn);
  var win = !winNode ? window : winNode;
  win.aopenNew(
    "/BasePicker/UsersPickerView/" + MyCore.encryptAes(params.join("&"), true),
    title,
    sizew,
    sizeh,
    () => {
      window.top.EventBus.removeEventListener("EVENT_BACKPERSONS", fn);
    },
    maxmin,
    time,
    offset
  );
}


/**
 * 产品清单选择器
 * @param {any} data {'babh':'默认选中的项目编号(可选)','selectIds':'当前已经选中的产品清单id','disabledIds':'禁用选择的产品清单id','checkChirld':checkBox联动子级，默认不联动,'checkParent':'checkBox联动父级，默认不联动'}
 * @param {Function} callBack 关闭后的回调 会返回选择器选择的数据
 * @param {any} viewOption {'winNode':'挂在的window对象，例如传入window.top (可选,默认为window)','title':'标题(可选,默认为单位选择)', 'sizew':'', sizeh, time, maxmin, offset}
 */
 export function openProjectListView(data, callBack, viewOption) {
  //传入页面的数据依赖参数
  var obj = {
    babh: "",
    selectIds: [],
    disabledIds: [],
    checkChirld: false,
    checkParent: false,
  };
  obj = Object.assign(obj, data);
  var routerParam = ["babh"]; //路由参数字段
  var params = [];
  routerParam.forEach((m) => {
    if (obj[m]) {
      params.push(m + "=" + obj[m]);
    }
  });

  //面板配置
  var option = Object.assign(
    {
      winNode: window.top,
      title: "产品清单选择",
      sizew: 0.4,
      sizeh: 0.8,
      time: null,
      maxmin: null,
      offset: null,
    },
    viewOption
  );

  var fn = function (data) {
    if (data && callBack) callBack.apply(null, [data]);
  };

  setLocalData("TEMP_SELECTED_PROJECTLIST_PARAM", JSON.stringify(obj)); //临时缓存一次数据

  window.top.EventBus.once("EVENT_PICK_PROJECTLIST", fn);
  option.winNode.aopenNew(
    "/BasePicker/ProjectListPickerView/" +
      MyCore.encryptAes(params.join("&"), true),
    option.title,
    option.sizew,
    option.sizeh,
    () => {
      window.top.EventBus.removeEventListener("EVENT_PICK_PROJECTLIST", fn);
    },
    option.maxmin,
    option.time,
    option.offset
  );
}