import * as amountTools from "./js/AmountTools";
import * as LayuiTools from "./js/LayuiTools";
import * as dateTools from "./js/DateTools";
import * as jsTools from "./js/JsTools";
import * as StringTools from "./js/StringTools";

import { eventBus,MyEvent } from "./js/EventTools";
import * as MyCore from "./js/MyCore";
import * as PickerTools from "./js/Picker";
import * as ServersTools from './js/ServersTools';
if (!window.jQuery || !window.layui) {
    alert('创建 jsTools失败 需要jQuery 和layui的支持');
} else {
    window.JsTools = jsTools;
    window.LayuiTools = LayuiTools;
    window.AmountTools = amountTools;
    window.DateTools = dateTools;
    window.EventBus = eventBus;
    window.MyCore = MyCore;
    window.PickerTools = PickerTools;
    window.StringTools = StringTools;
    window.ServersTools = ServersTools;
}

