/**
 *  @module LayuiTools
 *  @description  与layui相关的操作封装
 * */


/**
 * 验证截至时间必须要大于开始时间
 * 特别注意不要用c#封装的 LayuiHelper.GetDate来绑定,这里传入两个id会自动关联
 * 需要layui支持
 * @param {string} startTimeId 表示起始时间的关联控件id
 * @param {string} endTimeId 表示终止时间的关联控件id
 * @param {string} msg 自定义提示语言
 * @param {string} format 时间格式 默认yyyy-MM-dd
 * @param {string} trigger 控件触发方式默认 click
 */
export function ValidateStartAndEnd(startTimeId, endTimeId, msg, format, trigger) {
    layui.use('laydate', function () {
        var laydate = layui.laydate;
        // 时间选择器初始化 开始时间
        if (!msg || msg == "") msg = "起始时间不能小于终止时间";
        if (!format || format == "") format = "yyyy-MM-dd";
        if (!trigger || trigger == "") trigger = "click";
        laydate.render({
            elem: '#' + startTimeId,
            format,
            done: function (value, date, endDate) {
                var startDate = new Date(value).getTime();
                var endTime = new Date($('#' + endTimeId).val()).getTime();
                if (endTime < startDate) {
                    layer.msg(msg);
                    $('#' + startTimeId).val($('#' + endTimeId).val());
                }

            },
            trigger
        });
        laydate.render({ //结束时间
            elem: '#' + endTimeId,
            format,
            done: function (value, date, endDate) {
                var startDate = new Date($('#' + startTimeId).val()).getTime();
                var endTime = new Date(value).getTime();
                if (endTime < startDate) {
                    layer.msg(msg);
                    $('#' + endTimeId).val($('#' + startTimeId).val());
                }
            },
            trigger
        });
    });
}

/**
 * 把from表单转换成post方式,需要layui和 apost的支持
 * 需要layui支持
 * @param {string} submitBtnId layui-submit提交按钮的id
 * @param {string} url 要提交的url
 * @param {Function} callBack 提交完成后的回调数据在response返回 (response)=>void
 * @param {Function} beforeRequesetFunc 自定义处理数据回调 返回数据源 (data)=>any
 */
export function submitToPost(submitBtnId, url, callBack, beforeRequesetFunc) {
    $('#' + submitBtnId).attr("lay-filter", "submitBtn");
    layui.use(['form', "element"], function () {
        var form = layui.form; //只有执行了这一步，部分表单元素才会自动修饰成功
        form.on('submit(submitBtn)', function (data) {
            // console.log(data.elem) //被执行事件的元素DOM对象，一般为button对象
            // console.log(data.form) //被执行提交的form对象，一般在存在form标签时才会返回
            // console.log(data.field) //当前容器的全部表单字段，名值对形式：{name: value}
            var request = data.field;
            if (beforeRequesetFunc) {
                request = beforeRequesetFunc(data.field);
            }
            apost(url, request, function (response) {
                // console.log(response);
                if (callBack) {
                    callBack(response);
                    return;
                }
                if (response.status != 0) {
                    layer.alert(response['msg'], {
                        skin: 'layui-layer-molv',
                        title: "错误",
                        closeBtn: 0
                    });
                } else {
                    layer.alert("操作成功", {
                        skin: 'layui-layer-molv',
                        closeBtn: 0
                    }, function (idx) {
                        layer.close(idx);
                        window.document.location.reload();
                    });
                }
            }, 1);
            return false; //阻止表单跳转。如果需要表单跳转，去掉这段即可。
        });
        form.render();
    });
}

/**
 * 开启表单验证 支持后续添加
 *  验证长度:
 *          1、less：检测内容长度不能大于所配置长度 例如下面的例子，表示一个账号文本框，验证了必传且文字内容不能大于6个(其中less为开启条件 lay-less为限制的文本长度最大值)
 *          <input class="layui-input" name="account" placeholder="请输入账号"  lay-verify="required|less" lay-less="6" type="text">
 *          2、equal:检测内容长度不能大于所配置长度 例如下面的例子，表示一个身份信息文本框，验证了必传且文字内容必须为18个长度(其中equal为开启条件 lay-equal为限制的文本长度规定的值)
 *          <input class="layui-input" name="identity" placeholder="请输入身份号"  lay-verify="required|equal" lay-equal="18" type="text">
 *          3、uscc 企业社会信用编码验证
 *
 */
export function openCustomLayVerify() {
    layui.use(['form', 'element'], function () {
        var form = layui.form; //只有执行了这一步，部分表单元素才会自动修饰成功
        form.verify({
            less: (value, el) => {
                var len = el.getAttribute('lay-less');
                if (value.length > len) {
                    return `文字内容过多,当前文本框最多可以输入${len}个文字内容！`;
                }
                return '';
            },
            equal: (value, el) => {
                var len = el.getAttribute('lay-equal');
                if (value.length != len) {
                    return `当前文本框中必须输入${len}个文字内容`
                }
                return ''
            },
            uscc: (value, el) => {
                var patrn = /^[0-9A-Z]+$/;
                //18位校验及大写校验
                if ((Code.length != 18) || (patrn.test(Code) == false)) {
                    return '不是有效的统一社会信用编码！';
                }
                else {
                    var Ancode;//统一社会信用代码的每一个值
                    var Ancodevalue;//统一社会信用代码每一个值的权重 
                    var total = 0;
                    var weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28];//加权因子 
                    var str = '0123456789ABCDEFGHJKLMNPQRTUWXY';
                    //不用I、O、S、V、Z 
                    for (var i = 0; i < Code.length - 1; i++) {
                        Ancode = Code.substring(i, i + 1);
                        Ancodevalue = str.indexOf(Ancode);
                        total = total + Ancodevalue * weightedfactors[i];
                        //权重与加权因子相乘之和 
                    }
                    var logiccheckcode = 31 - total % 31;
                    if (logiccheckcode == 31) {
                        logiccheckcode = 0;
                    }
                    var Str = "0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y";
                    var Array_Str = Str.split(',');
                    logiccheckcode = Array_Str[logiccheckcode];


                    var checkcode = Code.substring(17, 18);
                    if (logiccheckcode != checkcode) {
                        return "不是有效的统一社会信用编码！";
                    }
                    return '';
                }
            }
        })
        //……
        //如果你的 HTML 是动态生成的，自动渲染就会失效
        //因此你需要在相应的地方，执行下述方法来进行渲染
        form.render();

    });
}


/**
 *  打开一个弹窗
 * @param {string} msg 要显示的文本
 * @param {Function} callBack 关闭后的回调方法 (可选)
 * @param {number} time  自动关闭的时间单位毫秒(ms) 默认为0，为0时候表示不关闭
 * @param {Array<string>} offset layui规则的offset
 */
export function openAlert(msg, callBack, time, offset) {
    layui.use('layer', function () {
        var index = layer.alert((msg == null || msg == "") ? "" : msg, {
            skin: 'layui-layer-molv',
            closeBtn: 0,
            time: ((time == null || time == "") ? 0 : time),
            offset: ((offset == null || offset == "") ? "auto" : offset)
        }, function () {
            layer.close(index);
            if (callBack) {
                callBack.call(null, null);
            }
        })
    })

}

/**
 * post请求 promise版本
 * @param {string} url 请求地址
 * @param {any} data 请求数据
 * @returns {Promise<any>} 返回值为服务器返回的原数据 例 {status:0,msg:'',data:{}}
 */
export async function apostAsync(url, data, useLoadingIcon = true, tip = "") {
    if (url == null || url == "") {
        aalert("请填写正确的请求地址！", time);
        return Promise.reject(new Error("请填写正确的请求地址！"));
    }
    return new Promise((r, j) => {
        if (tip == null || tip == "")
            tip = "玩命加载中...";

        var loadindex = null;
        if (useLoadingIcon == true) {
            loadindex = layer.msg(tip, {
                icon: 16,
                shade: 0.01,
                time: 0
            })
        }
        $.post(url, data, function (result, status, xhr) {
            if (loadindex != null)
                layer.close(loadindex);
            if (status == "success") {
                layer.close(loadindex);
                return r(result);
            } else {
                return j(new Error("服务器返回异常:" + status));
            }
        }, 'json').error(function (xhr, errorText, errorType) {
            if (loadindex != null) {
                layer.close(loadindex);
            }
            return j(new Error("服务器返回异常:" + errorText));

        })
    });
}

/**
 * 用post请求的方式传参打开一个页面
 * @param {string} url  请求地址
 * @param {any} option
 *      title 页面标题
 *      sizew 页面宽的值
 *      sizeh 页面高的值
 *      time 页面自毁时间0为永久有效,
 *      maxmin,
 *      offset layui偏移规则,
 *      callBack 关闭后的回调方法
 * @param {any} postData 请求的数据源
 */
export function openPost(url, option, postData) {
    let layerIndex;
    $.ajax({
        type: 'POST',
        url: url,//发送请求
        data: postData,
        dataType: "html",
        success: function (result) {
            var htmlCont = result;//返回的结果页面
            let { title, sizew, sizeh, time, maxmin, offset, callBack } = option;
            if (url == null || url == "") {
                layer.msg('url有误');
                return;
            }
            if (title == null)
                title = "";
            if (sizew == null || sizew == "" || sizew <= 0)
                sizew = 0.9;
            if (sizeh == null || sizeh == "" || sizeh <= 0)
                sizeh = sizew;
            if (time == null || time == "" || time < 0)
                time = 0;
            if (maxmin == null || maxmin == "")
                maxmin = false;
            var width = String(sizew).indexOf("px") != -1 ? sizew : $(window).width() * sizew + 'px';
            var height = String(sizeh).indexOf("px") != -1 ? sizeh : $(window).height() * sizeh + 'px';
            var openNum = getCookie("opentype");
            setCookie("opentype", openNum == null ? 1 : parseInt(openNum) + 1);
            layerIndex = layer.open({
                type: 1,
                title: title,
                shadeClose: true,
                shade: [0.3, '#000000'],
                maxmin: maxmin,
                time: time,
                area: [width, height],
                content: htmlCont,
                offset: (offset == null || offset == "") ? "auto" : offset,
                end: function () {
                    if (callBack) {
                        clearopen();
                        callBack.apply(null, null);
                    }
                }
            });
        },
        error:function(XMLHttpRequest, textStatus, errorThrow){
            console.log(textStatus||errorThrow);
        }
    });

}


