/**
 *  @module DateTools
 *  @description  时间相关操作工具方法
 * */

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
// 例子： 
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18 
export function FormatDate(date, fmt) {

    var o = {
        "M+": date.getMonth() + 1, //月份 
        "d+": date.getDate(), //日 
        "h+": date.getHours(), //小时 
        "m+": date.getMinutes(), //分 
        "s+": date.getSeconds(), //秒 
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
        "S": date.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

/**
 *  比较两个 yyyy-MM-dd hh:mm:ss的时间格式的大小， d2 - d1 返回时间戳
 *  值为正数表示 d2>d1 
 *  值为负数表示 d2<d1
 *  值相等 d2 = d1
 * @param {*} d1 
 * @param {*} d2 
 * @returns 
 */
export function DateDiff(d1, d2) {
    let sDate1 = Date.parse(d1);
    let sDate2 = Date.parse(d2);
    return sDate2 - sDate1;
}
/**
 *  比较两个yyyy-MM-dd hh:mm:ss格式的时间大小  
 * @param {*} start 
 * @param {*} end 
 * @param {*} useAbs 是否启用绝对值，启用之后始终返回正数天数,默认开启
 * @returns 
 */
export function Datedifference(start, end, useAbs = true) { //sDate1和sDate2是2006-12-18格式
    var dateSpan,
        iDays;
    ;
    dateSpan = DateDiff(end, start)
    if (useAbs) {
        dateSpan = Math.abs(dateSpan);
    }
    iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
    return iDays;
}

/**
 * 清理掉时间上的 时分秒毫秒
 * @param {*} date 
 * @returns 
 */
export function clearTime(date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

/**
 * 检查date1 是否在date2 之前 Date
 * @param {*} date1 
 * @param {*} date2 
 * @returns 
 */
export function isBefore(date1, date2) {
    if (date2 == null) {
        return false;
    }
    return (date1.getTime() < date2.getTime());
};

/**
 * 检查date1 是否在date2 之后 Date
 * @param {*} date1 
 * @param {*} date2 
 * @returns 
 */
export function isAfter(date1, date2) {
    if (date2 == null) {
        return false;
    }
    return (date1.getTime() > date2.getTime());
};
/**
 * 检查date1 是否与 date2 相等
 * @param {*} date1 
 * @param {*} date2 
 * @returns 
 */
export function equals(date1, date2) {
    if (date2 == null) {
        return false;
    }
    return (date1.getTime() == date2.getTime());
};

/**
 * 检查date1 是否与 date2 相等(忽略具体的时分秒)
 * @param {*} date1 
 * @param {*} date2 
 * @returns 
 */
export function equalsIgnoreTime(date1, date2) {
    if (date2 == null) {
        return false;
    }
    var d1 = clearTime(date1);
    var d2 = clearTime(date2);
    return (d1.getTime() == d2.getTime());
};