/**
 *  @module AmountTools
 *  @description  对金额数字类操作工具方法
 * */

/**
 * 对ele元素的值进行金额形式的格式化
 * @param {Element} el  ele元素 jq获取
 * @param {number} pointNum 金额后小数点几位默认为4位
 */
function setElementFormatAmount(el, pointNum = 4) {
    var oldVal = el.value;
    //去掉所有非数字、和. - 的字符
    oldVal = oldVal.replace(/[^0-9\-\.]+$/g, "");

    //第一个.符号保留
    oldVal = oldVal.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    //把首个字符是符号才保留负号
    var idx = oldVal.indexOf('-');
    if (idx == 0) {
        oldVal = oldVal.replace("-", "$#$");
    }
    oldVal = oldVal.replace(/\-/g, "").replace("$#$", "-");
    //保留小数点后面几位数字
    var arry = oldVal.split('.');
    var a = arry[0] ? arry[0] : '0';//给默认值0
    var b = "";
    if (arry.length == 2) {
        b = arry[1];
        b = b.substring(0, pointNum);
        b = "." + b;
    }
    el.val = a + b;
}

/**
 * 把数值格式化成金钱类型
 * @param {string|number} num  待转化的金额数值
 * @param {number} pointNum 小数点后保留几位
 * @returns {string} 带有小数点的金钱数值
 */
export function NumberToAmount(num, pointNum = 4) {
    let str = num.toString();
    var arry = str.split('.');
    var a = arry[0] ? arry[0] : '0';//给默认值0
    var b = "." + new Int8Array(pointNum).join('');
    if (arry.length == 2) {
        b = arry[1];
        var cnt = pointNum - b.length;
        for (var i = 0; i < cnt; i++) {
            b += '0';
        }
        b = "." + b;
    }
    return a + b;
}

/**
 * 直接设置某个表单元素的值格式化
 * @param {Element} ele  ele元素 jq获取
 * @param {number} pointNum 小数点后保留几位
 */
export function ElementFormatAmount(ele, pointNum) {
    var el = $(ele);
    el.val(NumberToAmount(el.val(), pointNum));
}

/**
 * 批量设置表单输入元素只能为金钱的格式 
 * @param {Array<string>} elementIds dom元素的id ['#id1','#id2',...]
 */
export function WatchInputFormatAmount(ids) {
    var select = ids.join(',');
    $(select).on("input", function () {
        setElementFormatAmount(this);
    })
}

/**
 * 批量设置表单元素补全金额格式
 * @param  {Array<string>} elementIds dom元素的id ['#id1','#id2',...]
 * @param {number} pointNum  小数点保留几位
 */
export function BlurElementToDecimal(ids, pointNum = 4) {
    //格式化金额4位数格式
    var select = ids.join(',');
    $(select).blur(function () {
        ElementFormatAmount(this, pointNum);
    });
}

/**
 * 把金钱转成大写中文金钱
 * @param {string|number} currencyDigits 待转换的小写金额
 * @returns {string} 大写金钱的值 例 '壹佰零贰元'
 */
export function DigitUppercase(currencyDigits) {

    var MAXIMUM_NUMBER = 99999999999.9999;
    var isNegative = Number(currencyDigits < 0);
    currencyDigits = currencyDigits.replace("-", "");
    var CN_Negative = isNegative ? "（负）" : "";

    // Predefine the radix characters and currency symbols for output:
    var CN_ZERO = "零";
    var CN_ONE = "壹";
    var CN_TWO = "贰";
    var CN_THREE = "叁";
    var CN_FOUR = "肆";
    var CN_FIVE = "伍";
    var CN_SIX = "陆";
    var CN_SEVEN = "柒";
    var CN_EIGHT = "捌";
    var CN_NINE = "玖";
    var CN_TEN = "拾";
    var CN_HUNDRED = "佰";
    var CN_THOUSAND = "仟";
    var CN_TEN_THOUSAND = "万";
    var CN_HUNDRED_MILLION = "亿";
    var CN_SYMBOL = "";
    var CN_DOLLAR = "元";
    var CN_TEN_CENT = "角";
    var CN_CENT = "分";
    var CN_INTEGER = "整";

    // Variables:
    var integral;    // Represent integral part of digit number.
    var decimal;    // Represent decimal part of digit number.
    var outputCharacters;    // The output result.
    var parts;
    var digits, radices, bigRadices, decimals;
    var zeroCount;
    var i, p, d;
    var quotient, modulus;

    // Validate input string:
    currencyDigits = currencyDigits.toString();
    if (currencyDigits == "") {
        alert("Empty input!");
        return "";
    }
    if (currencyDigits.match(/[^,.\d]/) != null) {
        alert("Invalid characters in the input string!");
        return "";
    }
    if ((currencyDigits).match(/^((\d{1,3}(,\d{3})*(.((\d{3},)*\d{1,3}))?)|(\d+(.\d+)?))$/) == null) {
        alert("Illegal format of digit number!");
        return "";
    }

    // Normalize the format of input digits:
    currencyDigits = currencyDigits.replace(/,/g, "");    // Remove comma delimiters.
    currencyDigits = currencyDigits.replace(/^0+/, "");    // Trim zeros at the beginning.
    // Assert the number is not greater than the maximum number.
    if (Number(currencyDigits) > MAXIMUM_NUMBER) {
        alert("Too large a number to convert!");
        return "";
    }

    // Process the coversion from currency digits to characters:
    // Separate integral and decimal parts before processing coversion:
    parts = currencyDigits.split(".");
    if (parts.length > 1) {
        integral = parts[0];
        decimal = parts[1];
        // Cut down redundant decimal digits that are after the second.
        decimal = decimal.substr(0, 2);
    }
    else {
        integral = parts[0];
        decimal = "";
    }
    // Prepare the characters corresponding to the digits:
    digits = new Array(CN_ZERO, CN_ONE, CN_TWO, CN_THREE, CN_FOUR, CN_FIVE, CN_SIX, CN_SEVEN, CN_EIGHT, CN_NINE);
    radices = new Array("", CN_TEN, CN_HUNDRED, CN_THOUSAND);
    bigRadices = new Array("", CN_TEN_THOUSAND, CN_HUNDRED_MILLION);
    decimals = new Array(CN_TEN_CENT, CN_CENT);
    // Start processing:
    outputCharacters = "";
    // Process integral part if it is larger than 0:
    if (Number(integral) > 0) {
        zeroCount = 0;
        for (i = 0; i < integral.length; i++) {
            p = integral.length - i - 1;
            d = integral.substr(i, 1);
            quotient = p / 4;
            modulus = p % 4;
            if (d == "0") {
                zeroCount++;
            }
            else {
                if (zeroCount > 0) {
                    outputCharacters += digits[0];
                }
                zeroCount = 0;
                outputCharacters += digits[Number(d)] + radices[modulus];
            }
            if (modulus == 0 && zeroCount < 4) {
                outputCharacters += bigRadices[quotient];
            }
        }
        outputCharacters += CN_DOLLAR;
    }
    // Process decimal part if there is:
    if (decimal != "") {
        for (i = 0; i < decimal.length; i++) {
            d = decimal.substr(i, 1);
            if (d != "0") {
                outputCharacters += digits[Number(d)] + decimals[i];
            }
        }
    }
    // Confirm and return the final output string:
    if (outputCharacters == "") {
        outputCharacters = CN_ZERO + CN_DOLLAR;
    }
    if (decimal == "") {
        outputCharacters += CN_INTEGER;
    }
    outputCharacters = CN_SYMBOL + CN_Negative + outputCharacters;
    return outputCharacters;
}