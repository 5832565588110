
/**
 *  @module ServersTools
 *  @description axios版本的网络请求封装.
 * */


import axios from 'axios';

var loadindex;//全局加载动画索引
export const Service = axios.create({
    timeout: 5000,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
});

/**
 * 请求拦截器设置加载动画
 */
Service.interceptors.request.use(config => {
    if (config['showLoading']) {
        loadindex = layer.msg("玩命加载中...", {
            icon: 16,
            shade: 0.01,
            time: 0
        });
    }

    return config;
});

/**
 * 响应拦截器
 */
Service.interceptors.response.use(response => {
    layer.close(loadindex);
    return response.data;
}, error => {
    console.log('TCL: error', error)
    const msg = error.Message !== undefined ? error.Message : ''
    layui.use('layer', function () {
        var index = layer.alert('网络错误' + msg, {
            skin: 'layui-layer-molv',
            closeBtn: 0,
            time: 0,
            offset: "auto"
        }, function () {
            layer.close(index);

        })
    })
    layer.close(loadindex);
    return Promise.reject(error)
});

/**
 * 请求接口(post)
 * @param {*} url 请求接口地址 例如  /MenuLoad/LoadAllMenu/
 * @param {*} data  携带的参数
 * @param {*} showLoading  本次是否显示进度条
 * @returns 
 */
export async function request(url, data, showLoading = true) {
    let response;
    try {
        response = await Service(url, {
            data: data,
            showLoading: showLoading
        })
    } catch (error) {
        return Promise.reject(error);
    }
    if (!response || response['status'] === undefined) {

        return Promise.reject(response);
    }
    return Promise.resolve(response);
}

/**
 * 一次性批量执行请求 返回的数据顺序和传入的相同
 * @param {*} requestList 
 * @param {*} showLoading 
 * @returns 
 */
export function requestAll(requestList, showLoading = true) {
    let idx;
    if (showLoading) {
        idx = layer.msg("玩命加载中...", {
            icon: 16,
            shade: 0.01,
            time: 0
        });
    }
    return new Promise((r, j) => {
        axios.all(requestList)
            .then(axios.spread(function (...args) {
                // 两个请求现在都执行完成
                r(args);
                layer.close(idx);
                return;
            }));
    });

}

