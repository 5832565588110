/**
 * 事件分发机制
 */
export class MyEvent {
  constructor() {
    this.$_events = [];
  }

  /**
   * 添加事件
   * @param {String} event 事件名称
   * @param {Function} callBack 事件回调方法
   * @param {object}  flag 标识 主要用于对同组事件分类
   */
  addEventListener(event, callBack, flag) {
    (this.$_events[event] || (this.$_events[event] = [])).push({
      fn: callBack,
      flag: flag,
    });
  }

  /**
   * 派发事件
   * @param {String} event
   * @param {any} data
   * @returns
   */
  dispatchEvent(event, data) {
    var fns = this.$_events[event].map((m) => m.fn);
    if (!fns) return false;
    for (let i = fns.length - 1; i >= 0; i--) {
      fns[i].call(null, data);
      // try {
      //     fns[i].call(null, data)
      // } catch (e) {
      //     new Error(e, `event handler for "${event}"`)
      // }
    }
    return true;
  }

  /**
   * 添加一次事件用后销毁
   * @param {String} event
   * @param {Function} callBack
   * @param {object}  flag 标识 主要用于对同组事件分类
   */
  once(event, callBack, flag) {
    var self = this;
    var onceCallback = function (data) {
      self.removeEventListener(event, callBack);
      callBack.call(null, data);
    };
    onceCallback.once = callBack;
    this.addEventListener(event, onceCallback, flag);
  }

  /**
   * 删除事件
   * @param {String} event
   * @param {Function} callBack
   * @returns
   */
  removeEventListener(event, callBack) {
    var eventObjs = this.$_events[event];
    if (!eventObjs) return false;
    let fn;
    for (let i = eventObjs.length - 1; i >= 0; i--) {
      fn = eventObjs[i].fn; //fn.once 表示once
      if (fn === callBack || fn.once === callBack) {
        eventObjs.splice(i, 1);
        return true;
      }
    }
    return false;
  }

  /**
   * 删除同一个flag下的所有事件
   * @param {*} flag
   * @returns
   */
  removeEventsByFlag(flag) {
    if (!flag) return false;
    var eventKeys = Object.keys(this.$_events);
    for (let i = 0; i < eventKeys.length; i++) {
      var eventKey = eventKeys[i];
      var eventObjs = this.$_events[eventKey]; //获取到事件对象
      for (let j = eventObjs.length - 1; j >= 0; j--) {
        var curtFlag = eventObjs[j].flag;
        if (!curtFlag) continue;
        if (curtFlag === flag) {
          eventObjs.splice(j, 1);
        }
      }
      if (eventObjs.length == 0) delete this.$_events[eventKey];
    }
  }
}

/**
 *  @module EventBus
 *  @description 全局事件总成;
 *  @returns {MyEvent}
 * */
var eventBus = new MyEvent();
export var eventBus;
