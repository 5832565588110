
/**
 *  @module MyCore
 *  @description 文件中所有方法是大系统业务相关的方法，复用性较低
 * */


import CryptoJS from "crypto-js";

/**
 * 加密AES 与C#版本对应 
 * @param {string} msg  待加密文本
 * @param {boolean} isRouter 是否是路由参数,当文本为路由参数时候会把/替换掉
 * @returns {string} 加密后的文本 
 */
export function encryptAes(msg,isRouter) {
    msg = CryptoJS.enc.Utf8.parse(msg);
    var key = CryptoJS.enc.Utf8.parse("abc0123456789def");
    var iv = CryptoJS.enc.Utf8.parse("abc0123456789def");
    var encrypted = CryptoJS.AES.encrypt(msg, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    });
    if(isRouter)return encrypted.toString().replace(/\//g,'≮');
    return encrypted.toString()
}
/**
 * 解密AES
 * @param {string} msg 待解密文本
 * @param {string} isRouter 是否是路由文本
 * @returns {string} 解密后的文本
 */
export function decryptAes(msg,isRouter) {
    if(isRouter)msg = msg.replace(/≮/g,'/');
    msg = CryptoJS.enc.Utf8.parse(msg);
    var key = CryptoJS.enc.Utf8.parse("abc0123456789def");
    var iv = CryptoJS.enc.Utf8.parse("abc0123456789def");
    var decrypted = CryptoJS.AES.decrypt(msg, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    });
    return CryptoJS.enc.Utf8.stringify(decrypted)
}
