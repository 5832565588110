/**
 *  @module StringTools
 *  @description 与字符串相关的一些工具处理方法
 * */

/**
 * html解码
 * @param {*} str
 * @returns
 */
export function escape2Html(str) {
  var arrEntities = {
    lt: "<",
    gt: ">",
    nbsp: " ",
    amp: "&",
    quot: '"',
    "#39": "'",
  };
  return str.replace(/&(lt|gt|nbsp|amp|quot|#39);/gi, function (all, t) {
    return arrEntities[t];
  });
}
/**
 *  html编码
 * @param {string} sHtml
 * @returns {string}
 */
export function html2Escape(sHtml) {
  return sHtml.replace(/[<>&"]/g, function (c) {
    return {
      "<": "<",
      ">": ">",
      "&": "&",
      '"': '"',
    }[c];
  });
}
/**
 * 去除字符串中所有空格
 * @param {string} str
 * @returns {string}
 */
export function trim(str) {
  return str.replace(/\s*/g, "");
}
/**
 * 去除字符串中开头的空格
 * @param {string} str
 * @returns {string}
 */
export function trimStart(str) {
  return str.replace(/^\s*/, "");
}
/**
 * 去除字符串中结尾的空格
 * @param {string} str
 * @returns {string}
 */
export function trimEnd(str) {
  return str.replace(/(\s*$)/g, "");
}
/**
 * 去除字符串中头部和尾部的空格
 * @param {string} str
 * @returns {string}
 */
export function trimStartEnd(str) {
  return str.replace(/^\s*|\s*$/g, "");
}

/**
 * 检验 字符串t1 是否是以字符串t2开头
 * @param {string} t1
 * @param {string} t2
 * @param {boolean} i  忽略大小写
 * @returns {string}
 */
export function startsWith(t1, t2, i) {
  if (!i) {
    return t2 == this.substring(0, t2.length);
  } else {
    return t2.toLowerCase() == t1.substring(0, t2.length).toLowerCase();
  }
}
/**
 * 检验 字符串t1 是否是以字符串t2结尾
 * @param {string} t1
 * @param {string} t2
 * @param {boolean} i  忽略大小写
 * @returns {string}
 */
export function endsWith(t1, t2, i) {
  if (!i) {
    return t2 == this.substring(this.length - t2.length);
  } else {
    return (
      t2.toLowerCase() == this.substring(t1.length - t2.length).toLowerCase()
    );
  }
}

/**
 * 把一段字符串中的 from全部替换成to
 * @param {string} str
 * @param {string} from
 * @param {boolean} to
 * @returns {string}
 */
export function replaceAll(str, from, to) {
  return str.replace(new RegExp(RegExp.quote(from), "g"), to);
}

/**
 * 把html简化成纯文本
 * @param {*} html
 * @returns
 */
export function getSimpleText(html) {
  const re1 = new RegExp("<.+?>", "g"); // 匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
  const arrEntities = {
    lt: "<",
    gt: ">",
    nbsp: " ",
    amp: "&",
    quot: '"',
    ldquo: "“",
    mdash: "—",
    rdquo: "”",
  };
  return html
    .replace(re1, "")
    .replace(/&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi, function (all, t) {
      return arrEntities[t];
    })
    .replace(/\n/g, "");
}
