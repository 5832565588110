/**
 *  @module JsTools
 *  @description  一些js相关的操作工具类，例如对浏览器缓存进行读写的操作等
 * */

export function ArrayIndexOf(arry, searchElement, fromIndex) {
  if (arry == null) {
    throw new TypeError();
  }
  var t = Object(arry);
  var len = t.length >>> 0;
  if (len === 0) {
    return -1;
  }
  var n = 0;
  if (arguments.length > 0) {
    n = Number(arguments[1]);
    if (n != n) {
      // shortcut for verifying if it's NaN
      n = 0;
    } else if (n != 0 && n != Infinity && n != -Infinity) {
      n = (n > 0 || -1) * Math.floor(Math.abs(n));
    }
  }
  if (n >= len) {
    return -1;
  }
  var k = n >= 0 ? n : Math.max(len - Math.abs(n), 0);
  for (; k < len; k++) {
    if (k in t && t[k] === searchElement) {
      return k;
    }
  }
  return -1;
}

/**
 * 获取object对象的size大小
 * @param {any} obj
 * @returns {number}  大小
 */
export function GetObjectSize(obj) {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
}

/**
 * 比较两个obj是否相等，对值进行比较，无关地址
 * @param {any} objA
 * @param {any} objB
 * @returns {boolean} 返回true为相同
 */
export function isObjectValueEqual(objA, objB, isStrict) {
  // 取对象 A 和 B 的属性名数组
  const aProps = Object.getOwnPropertyNames(objA);
  const bProps = Object.getOwnPropertyNames(objB);
  // 长度是否一致
  if (aProps.length !== bProps.length) {
    return false;
  }
  // 循环取出属性名，再判断属性值是否一致
  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];
    const type = typeof objA[propName];
    if (type === "object" && type != null) {
      // 值是对象类型就递归
      if (!isObjectValueEqual(objA[propName], objB[propName])) {
        // 注：不能直接返回方法
        return false;
      }
    } else {
      if (
        (isStrict && objA[propName] !== objB[propName]) ||
        objA[propName] != objB[propName]
      ) {
        return false;
      }
    }
  }
  return true;
}
/***
 * 向浏览器中写入缓存
 * @param {string} key 对应的键值
 * @param {any} value  要存入的数据
 */
export function SetStorageObject(key, value) {
  if (typeof value === "string") {
    window.localStorage.setItem(key, value);
    return true;
  } else if (typeof value === "object") {
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
  }
  console.warn("缓存%s记录失败,目前只支持存入string 和json object 格式", key);
  return false;
}

/**
 * 从浏览器中拿出缓存
 * @param {string} key
 * @returns {string} 返回缓存数据 ,如果没有返回null
 */
export function GetStorageObject(key, isObj) {
  var cache = window.localStorage.getItem(key);
  if (!cache) return null;
  return isObj ? JSON.parse(cache) : cache;
}

/**
 * html 编码 需要jquery支持
 * @param {string} value  要编码的html
 * @returns {string} 返回编码后的文本
 */
export function htmlEncode(value) {
  //create a in-memory div, set it's inner text(which jQuery automatically encodes)
  //then grab the encoded contents back out.  The div never exists on the page.
  return $("<div/>").text(value).html();
}
/**
 * html 解码 需要jquery支持
 * @param {string} value  要解码的html
 * @returns {string} 返回解码后的文本
 */
export function htmlDecode(value) {
  return $("<div/>").html(value).text();
}
